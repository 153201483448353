import { NotificationGroup } from "../interfaces/notification-group.interface";

export class LoadNotificationGroups {
  public static readonly type = "[NotificationGroup] load notification groups";
  constructor(
    public skipCount: number,
    public maxResultsCount: number
  ) {}
}

export class AddNotificationGroup {
  public static readonly type = "[NotificationGroup] add notification group";
  constructor(
    public payload: NotificationGroup,
    public callbackSuccess: () => void,
  ) {}
}

export class UpdateNotificationGroup {
  public static readonly type = "[NotificationGroup] update notification group";
  constructor(
    public payload: NotificationGroup,
    public callbackSuccess: () => void,
  ) {}
}

export class RemoveNotificationGroup {
  public static readonly type = "[NotificationGroup] remove notification group";
  constructor(
    public notificationGroupId: string,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class LoadUserOptions {
  public static readonly type = "[NotificationGroup] load user options";
  constructor(
    public readonly unionId: number,
    public readonly employerId: number
  ) {}
}