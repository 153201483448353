import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { getUpdatedItems } from "../../../../shared/utils/utils";
import { UsersPermissionsService } from "../../users-permissions/users-permissions.service";
import { NotificationGroupService } from "../services/notification-groups.service";
import { AddNotificationGroup, LoadNotificationGroups, LoadUserOptions, RemoveNotificationGroup, UpdateNotificationGroup } from "./notification-group.actions";
import { NotificationGroupStateModel } from "./notification-group.model";

@Injectable()
@State<NotificationGroupStateModel>({
  name: "notificationGroup",
  defaults: {
    loading: false,
    saving: false,
    filteredUsers: [],
  }
})
export class NotificationGroupsState {
  constructor(
    private notificationGroupsService: NotificationGroupService,
    private usersPermissionsService: UsersPermissionsService
  ) {}

  @Selector() static notificationGroups(state: NotificationGroupStateModel) { return state.items; }
  @Selector() static totalCount(state: NotificationGroupStateModel) { return state.totalCount; }
  @Selector() static filteredUsers(state: NotificationGroupStateModel) { return state.filteredUsers; }
  @Selector() static loading(state: NotificationGroupStateModel) { return state.loading; }
  @Selector() static saving(state: NotificationGroupStateModel) { return state.saving; }

  @Action(LoadNotificationGroups)
  loadNotificationGroups(ctx: StateContext<NotificationGroupStateModel>, action: LoadNotificationGroups) {
    ctx.patchState({ items: [], loading: true });
    return this.notificationGroupsService.getNotificationGroups(action.skipCount, action.maxResultsCount).subscribe(
      (response) => {
        ctx.patchState({
          items: response.items,
          totalCount: response.totalCount,
          loading: false
        });
      }
    );
  }

  @Action(AddNotificationGroup)
  addNotificationGroup(ctx: StateContext<NotificationGroupStateModel>, action: AddNotificationGroup) {
    ctx.patchState({ saving: true });

    return this.notificationGroupsService.createNotificationGroup(action.payload).pipe(
      tap(
        (response) => {
          action.callbackSuccess();
          const state = ctx.getState();
          ctx.patchState({
            items: [response, ...state.items],
            totalCount: state.totalCount + 1,
            saving: false
          });
        },
        (error) => {
          ctx.patchState({ saving: false });
        }
      )
    );
  }

  @Action(UpdateNotificationGroup)
  updateNotificationGroup(ctx: StateContext<NotificationGroupStateModel>, action: UpdateNotificationGroup) {
    ctx.patchState({ saving: true });

    return this.notificationGroupsService.updateNotificationGroup(action.payload).pipe(
      tap(
        (response) => {
          action.callbackSuccess();
          const state = ctx.getState();
          ctx.patchState({
            items: getUpdatedItems(response, state.items),
            saving: false
          });
        },
        (error) => {
          ctx.patchState({ saving: false });
        }
      )
    );
  }

  @Action(RemoveNotificationGroup)
  removeNotificationGroup(ctx: StateContext<NotificationGroupStateModel>, action: RemoveNotificationGroup) {
    return this.notificationGroupsService.removeNotificationGroup(action.notificationGroupId).subscribe(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: state.items.filter(item => { return item.id !== action.notificationGroupId; }),
          totalCount: state.totalCount - 1
        });
      },
      (error) => {
        action.callbackError();
      }
    );
  }

  @Action(LoadUserOptions)
  loadUserOptions(ctx: StateContext<NotificationGroupStateModel>, action: LoadUserOptions) {
    this.usersPermissionsService.getUsers(0, 1000, '', action.unionId, action.employerId).subscribe(
      (response) => {
        ctx.patchState({
          filteredUsers: response.items.map(e => { return {
            value: e.id,
            text: `${e.name} ${e.surname} (${e.userName})`,
          }})
        });
      }
    );
  }
}
